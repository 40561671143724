import axios from "axios";

// export const baseURL = "http://localhost:5000"; 
export const baseURL = "https://api.pranucollection.com";
const Api = axios.create({
  baseURL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});
const config = {
  headers: {
    authorization: `Bearer ${localStorage.getItem("token")}`,
  },
};
// Category APi
export const addCategoryApi = (categoryData) => Api.post('/api/category/add-category', categoryData);
export const getAllCategoryApi = () => Api.get('/api/category/getAllCategory');

// Subcategory API
export const getAllSubcategoriesApi = () => Api.get('/api/subcategory/all');
export const getSubCategoryByIdApi = () => Api.get('/api/subcategory/subcategory/:id');
export const addSubCategoryApi = (subCategoryData) => Api.post('/api/subcategory/addsubcategory', subCategoryData);
export const getSubCategoriesByCategoryApi = (categoryId) => Api.get(`/api/subcategory/category/${categoryId}`);

// Product API
export const getAllProductApi = () => Api.get('/api/product/getAllProducts');
export const addProductApi = (productData) => Api.post('/api/product/add-product', productData, {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

// user auth api
export const loginApi = (loginData) => Api.post('/api/login', loginData);
export const registerApi = (registerData) => Api.post('api/register', registerData);
export const logoutUserApi =()=>Api.post("api/logout");

// Gallery API
export const createGalleryApi = (galleryData) =>
  Api.post('/api/gallery/addGallery', galleryData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
export const getAllGalleryApi = () => Api.get('/api/gallery/getAllGallery');

