import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { getAllProductApi, getAllCategoryApi, getAllSubcategoriesApi, baseURL } from '../../../Apis/Api'; // Adjust the import path based on your project structure
import ProductModal from './view/ViewProductModel';

const ViewProduct = () => {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  
  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const productResponse = await getAllProductApi();
        const categoryResponse = await getAllCategoryApi();
        const subCategoryResponse = await getAllSubcategoriesApi(); // Fetch all subcategories

        if (productResponse.status === 200) {
          setProducts(productResponse.data.products || []);
        } else {
          setError('Failed to fetch products');
        }

        if (categoryResponse.status === 200) {
          setCategories(categoryResponse.data.categories || []);
        } else {
          setError('Failed to fetch categories');
        }

        if (subCategoryResponse.status === 200) {
          setSubCategories(subCategoryResponse.data.subCategories || []); // Set the fetched subcategories
        } else {
          setError('Failed to fetch subcategories');
        }
      } catch (error) {
        setError('An error occurred while fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchAllData(); 
  }, []);

  
  const handleView = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };


  const handleEdit = (productId) => {
    console.log(`Editing product with id: ${productId}`);
  };

  const handleDelete = (productId) => {
    console.log(`Deleting product with id: ${productId}`);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

   // Create a map for categories
   const categoryMap = categories.reduce((map, category) => {
    map[category._id] = category.name;
    return map;
  }, {});

  // Create a map for subcategories
  const subCategoryMap = subCategories.reduce((map, subCategory) => {
    map[subCategory._id] = subCategory.name; // Store subcategory name
    return map;
  }, {});

  return (
    <div>
      <div className="total-product p-2 gap-4 bg-gray-100 rounded-md w-fit flex justify-between items-center mt-5">
        <div>
          <h2 className="flex gap-2 items-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="m7.875 14.25 1.214 1.942a2.25 2.25 0 0 0 1.908 1.058h2.006c.776 0 1.497-.4 1.908-1.058l1.214-1.942M2.41 9h4.636a2.25 2.25 0 0 1 1.872 1.002l.164.246a2.25 2.25 0 0 0 1.872 1.002h2.092a2.25 2.25 0 0 0 1.872-1.002l.164-.246A2.25 2.25 0 0 1 16.954 9h4.636M2.41 9a2.25 2.25 0 0 0-.16.832V12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 12V9.832c0-.287-.055-.57-.16-.832M2.41 9a2.25 2.25 0 0 1 .382-.632l3.285-3.832a2.25 2.25 0 0 1 1.708-.786h8.43c.657 0 1.281.287 1.709.786l3.284 3.832c.163.19.291.404.382.632M4.5 20.25h15A2.25 2.25 0 0 0 21.75 18v-2.625c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125V18a2.25 2.25 0 0 0 2.25 2.25Z" />
            </svg>
            Total Listed product on site: {products.filter(product => product.showProductinSite).length}
          </h2>
        </div>
      </div>
      <div className="text-[#AB3430] font-bold text-3xl mt-5">All Products</div>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg rounded-xl mt-3">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Product Image</th>
              <th scope="col" className="px-6 py-3">Product Name</th>
              <th scope="col" className="px-6 py-3">Category</th>
              <th scope="col" className="px-6 py-3">SubCategory</th>
              <th scope="col" className="px-6 py-3">Sizes and Quantities</th>
              <th scope="col" className="px-6 py-3">Total Quantity</th>
              <th scope="col" className="px-6 py-3">Show Product</th>
              <th scope="col" className="px-6 py-3">Actions</th>
            </tr>
          </thead>
          <tbody>
            {products
              .filter(product => product.showProductinSite) // Only show products where showProductinSite is true
              .map(product => (
                <tr key={product._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <td className="px-6 py-4 flex items-center gap-2">
                    <img src={`${baseURL}/${product.images[0]}`} alt="product" className="h-[100px] w-[70px]" />
                  </td>
                  <td className="px-6 py-4">{product.name}</td>
                  <td className="px-6 py-4">{categoryMap[product.category]}</td>
                  <td className="px-6 py-4">{subCategoryMap[product.subcategory] || 'N/A'}</td> {/* Update here */}
                  <td className="px-6 py-4">
                    {product.sizes.map(size => (
                      <div key={size._id}>
                        {size.size}: {size.colors.map(color => (
                          <span key={color._id}>{color.color} ({color.quantity})</span>
                        )).reduce((prev, curr) => [prev, ', ', curr])}
                      </div>
                    ))}
                  </td>
                  <td className="px-6 py-4">{product.totalQuantity}</td>
                  <td className="px-6 py-4">
                    {product.showProductinSite ? (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-green-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                      </svg>
                    ) : (
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    )}
                  </td>
                  <td className="px-6 py-4 flex gap-2">
                  <button onClick={() => handleView(product)}>
                      <FontAwesomeIcon icon={faEye} />
                    </button>
                    <button onClick={() => handleEdit(product._id)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </button>
                    <button onClick={() => handleDelete(product._id)}>
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
      {isModalOpen && (
        <ProductModal
          product={selectedProduct}
          categories={categories} // Pass categories here
          subCategories={subCategories} // Pass subCategories here
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default ViewProduct;
