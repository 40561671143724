import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, useEffect } from "react";
import Home from "./pages/Home/Home";
import Navbar from "./pages/component/Navbar";
import './app.css';
import AdminDashboard from "./pages/admin/AdminDashboard";
import Footer from "./pages/component/Footer";
import AboutUs from "./pages/Home/AboutUs";
import Register from "./pages/loginRegister/Register";
import Profile from "./pages/profile/profile";
import ViewProduct from "./pages/viewProduct/ViewProduct";
import AddCard from "./pages/addToCard/AddCard";
import AdminRoutes from "./PrivateRoutes/AdminRoutes";
import Gallery from "./pages/Home/Gallery";
import PageNotFound from "./pages/component/PageNotFound";
import { Toaster } from "react-hot-toast";
import Category from "./pages/Home/Category";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  // Check login status on initial render
  useEffect(() => {
    const token = localStorage.getItem('token'); // Assume you store token on login
    if (token) {
      setIsLoggedIn(true); // User is logged in
    } else{
      setIsLoggedIn(false);
    }
  }, [localStorage.getItem('token')]);

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Router>
        {/* Pass isLoggedIn and setIsLoggedIn to Navbar */}
        <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/viewProduct" element={<ViewProduct />} />
          <Route path="/viewProduct/:id" element={<ViewProduct />} />
          <Route path ="/category" element={<Category />} />
          <Route path="/addCart" element={<AddCard />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route element={<AdminRoutes />}>
            <Route path="/admindashboard" element={<AdminDashboard />} />
          </Route>
          <Route path="*" element={<PageNotFound/>} />
        </Routes>

        <Footer  />
      </Router>
    </>
  );
}

export default App;
