import React, { useState, useEffect } from 'react';
import { getAllCategoryApi, getAllSubcategoriesApi } from '../../Apis/Api'; // Importing the API functions

const Category = () => {
  const [categories, setCategories] = useState([]); // Ensure default value is an empty array
  const [subCategories, setSubCategories] = useState([]); // Ensure default value is an empty array
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch categories and subcategories on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryResponse = await getAllCategoryApi();
        const subCategoryResponse = await getAllSubcategoriesApi();
        
        setCategories(categoryResponse.data.categories || []); // Ensure fallback to an empty array
        setSubCategories(subCategoryResponse.data.subcategories || []); // Ensure fallback to an empty array
        setLoading(false);
      } catch (err) {
        setError('Failed to load categories');
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  // Filter categories based on search term
  const filteredCategories = categories?.filter((category) =>
    category.name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) return <p>Loading categories...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div className="p-4 max-w-4xl mx-auto">
      {/* Search Bar */}
      <div className="mb-6">
        <input
          type="text"
          placeholder="Search categories..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-400"
        />
      </div>

      {/* Category List */}
      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-4">
        {filteredCategories.length > 0 ? (
          filteredCategories.map((category, index) => (
            <div
              key={index}
              className="bg-blue-500 text-white p-4 rounded-lg shadow-lg hover:bg-blue-600 transition duration-200"
            >
              <h3>{category.name}</h3>
              <ul>
                {subCategories
                  .filter(sub => sub.categoryId === category.id)
                  .map((subCategory, subIndex) => (
                    <li key={subIndex} className="text-sm">
                      {subCategory.name}
                    </li>
                  ))}
              </ul>
            </div>
          ))
        ) : (
          <p className="text-gray-500">No categories found.</p>
        )}
      </div>
    </div>
    
  );
};

export default Category;
