import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from 'react-router-dom'; // Updated to use useNavigate
import { Toaster, toast } from 'react-hot-toast';
import { logoutUserApi } from "../../Apis/Api";



const Profile = () => {
  const navigate = useNavigate(); // Updated to use useNavigate


  const handleLogout = async (event) => {
    event.preventDefault(); // Prevent default form action
    try {
      const response = await logoutUserApi();
      if (response.data.success) {
        toast.success(response.data.message);

        // Clear any client-side stored authentication data
        localStorage.removeItem('authToken');
        // Optionally, clear other local storage items if needed
        localStorage.clear();

        // Redirect to login page or home page
        navigate('/'); // Updated to use navigate
      } else {
        toast.error('Failed to log out');
      }
    } catch (err) {
      console.error('Error logging out:', err);
      toast.error('Failed to log out');
    }
  };





  // console.log('userId: ', userId);
  // const fileInputRef = useRef(null);

  // const handleImageClick = () => {
  //   fileInputRef.current.click();
  // };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];
  //   setUser((prevUser) => ({ ...prevUser, avatar: file }));
  // };


  // const initialUserState = {
  //   fName: "",
  //   lName: "",
  //   email: "",
  //   phoneNumber: "",
  //   address: "",
  //   avatar: "",
  // };

  // const [user, setUser] = useState(initialUserState);

  // useEffect(() => {
  //   const fetchUser = async () => {
  //     try {
  //       const res = await getUserByIdApi(userId);

  //       console.log("getUserByIdApi response: ", res);

  //       if (!res.data || !res.data.success) {
  //         const errorMessage = res.data ? res.data.message : "Unexpected response format";
  //         toast.error(errorMessage);
  //       } else {
  //         setUser(res.data.user);
  //       }
  //     } catch (err) {
  //       console.error("Error fetching user:", err);
  //       toast.error("Internal server error");
  //     }
  //   };

  //   if (userId) {
  //     fetchUser();
  //   } else {
  //     toast.error("User ID is missing");
  //   }
  // }, [userId]);

  // const handleUserUpdate = async () => {
  //   try {
  //     const formData = new FormData();
  //     formData.append("userId", userId); // Include userId here
  //     formData.append("fName", user.fName);
  //     formData.append("lName", user.lName);
  //     formData.append("email", user.email);
  //     formData.append("phoneNumber", user.phoneNumber);
  //     formData.append("address", user.address);

  //     // Append avatar if it's a File object
  //     if (user.avatar instanceof File) {
  //       formData.append("avatar", user.avatar);
  //     }

  //     // Call your updateUserApi with formData
  //     const res = await updateUserApi(formData, userId);

  //     // Assuming your API response has a success message
  //     if (res.data.success) {
  //       toast.success(res.data.message);
  //     } else {
  //       toast.error(res.data.message || "Failed to update profile");
  //     }
  //   } catch (error) {
  //     console.error("Error updating user:", error);
  //     toast.error("Internal server error");
  //   }
  // };


  const placeholderAvatar = "https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI=";

  return (
    <>

    <div className="p-5 text-center bg-[#E3DCD7]">
    <div className=' font-bold text-3xl valky'>Profile Setting</div>
    </div>
   
            <div className='container mx-auto '>
          
              <div className='mx-10 '>
                <div className='p-4 bg-white  rounded-md h-fit'>
                 
                  <div className='text-gray-600 font-bold'>Update Your Profile</div>
                  <div className='grid lg:grid-cols-2 gap-4'>
                    <div className="div">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        First Name
                      </label>
                      <input
                        // onChange={(event) => setUser((user) => ({ ...user, fName: event.target.value }))}
                        // value={user.fName}
                        placeholder="your First name"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 pl-5 pr-5 mb-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="div">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Last Name
                      </label>
                      <input
                        // onChange={(event) => setUser((user) => ({ ...user, lName: event.target.value }))}
                        // value={user.lName}
                        placeholder="your last name"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 pl-5 pr-5 mb-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="div">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Email
                      </label>
                      <input
                        // onChange={(event) => setUser((user) => ({ ...user, email: event.target.value }))}
                        // value={user.email}
                        placeholder="your email"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 pl-5 pr-5 mb-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="div">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Address
                      </label>
                      <input
                        // onChange={(event) => setUser((user) => ({ ...user, address: event.target.value }))}
                        // value={user.address || ''}
                        placeholder="Address"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 pl-5 pr-5 mb-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                    <div className="div">
                      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">
                        Phone Number
                      </label>
                      <input
                        // onChange={(event) => setUser((user) => ({ ...user, phoneNumber: event.target.value }))}
                        // value={user.phoneNumber}
                        placeholder="phone number"
                        type="text"
                        className="block w-full rounded-md border-0 py-1.5 pl-5 pr-5 mb-3 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-sky-600 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                  <button className="bg-[#AB3430] w-full lg:w-auto px-3 rounded-md border-0 py-1.5 pl-5 pr-5 mb-2 hover:bg-red-200 text-white">
                    Update Profile
                  </button>
                </div>
                {/* <ChangePassword/> */}
                <button onClick={handleLogout}
                    type="submit" >Logout</button>


              </div>

            </div>
            </>
   
  );
};

export default Profile;
