import React, { useState } from 'react';
import logo from "../../assets/logos/logo.png";

// import { useNavigate } from 'react-router-dom';

import AddProduct from './addProduct/AddProduct.jsx';

import Dashboard from './Dashboard.jsx';
import AddCategory from './addCategory/AddCategory.jsx';
import CurrentDate from '../component/CurrentDate.jsx';

import Gallerys from './gallery/Gallerys.jsx';
import AddSubCategory from './addCategory/AddSubCategory.jsx';



const AdminDashboard = () => { 
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

//   const navigate = useNavigate();
  const [activeContainer, setActiveContainer] = useState('dashboard');

  const handleContainerChange = (containerName) => {
    setActiveContainer(containerName);
  };

  

  return (
    <>
      <div>
        <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
          <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-start rtl:justify-end">
                <button 
                  onClick={toggleSidebar} 
                  data-drawer-target="logo-sidebar" 
                  data-drawer-toggle="logo-sidebar" 
                  aria-controls="logo-sidebar" 
                  type="button" 
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                >
                  <span className="sr-only">Open sidebar</span>
                  <svg 
                    className="w-6 h-6" 
                    aria-hidden="true" 
                    fill="currentColor" 
                    viewBox="0 0 20 20" 
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path 
                      clipRule="evenodd" 
                      fillRule="evenodd" 
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                  </svg>
                </button>
                <div className="flex ms-2 md:me-24">
                  <img src={logo} className="h-8 me-3" alt="Logo" />

                </div>
              </div>
              <div className="flex items-center">
                <div className="flex items-center ms-3">
                  <div className='lg:flex gap-2'>
                    <CurrentDate/>
                    <div className='hidden lg:block'>

                Admin Dashboard
                    </div>
                    <button 
                      type="button" 
                      className="flex text-sm bg-gray-800 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600" 
                      aria-expanded="false" 
                      data-dropdown-toggle="dropdown-user"
                    >
                      <span className="sr-only">Open user menu</span>
                    </button>
              
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>
        <aside 
          className={`fixed top-0 left-0 z-40 w-64 h-screen pt-20 transition-transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"} bg-gray-100 border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700`} 
          aria-label="Sidebar"
        >
          <div className="h-full px-3 pb-4 overflow-y-auto bg- dark:bg-gray-800">
            <ul className="space-y-2 font-medium">
              <li>
                <button 
                  onClick={() => handleContainerChange('dashboard')} 
                  className={`w-[100%] flex items-center p-2 text-gray-900 rounded-r-lg hover:bg-red-100 dark:hover:bg-gray-700 group ${activeContainer === 'dashboard' ? 'bg-red-100 text-[#AB3430] border-l-4 border-[#AB3430]' : ''}`}
                >
                  <span className={`w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-[#AB3430] dark:group-hover:text-white`}>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 24 24" 
                      fill="currentColor" 
                      className={`w-6 h-6 ${activeContainer === 'dashboard' ? 'text-[#AB3430]' : ''}`}
                    >
                      <path 
                        fillRule="evenodd" 
                        d="M3 6a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3V6Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3v2.25a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3V6ZM3 15.75a3 3 0 0 1 3-3h2.25a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3H6a3 3 0 0 1-3-3v-2.25Zm9.75 0a3 3 0 0 1 3-3H18a3 3 0 0 1 3 3V18a3 3 0 0 1-3 3h-2.25a3 3 0 0 1-3-3v-2.25Z" 
                        clipRule="evenodd" 
                      />
                    </svg>
                  </span>
                  <div className={`ms-3 ${activeContainer === 'dashboard' ? 'text-[#AB3430]' : ''}`}>Dashboard</div>
                </button>
              </li>
              <li>
                <button 
                  onClick={() => handleContainerChange('addProduct')} 
                  className={`w-[100%] flex items-center p-2 text-gray-900 rounded-r-lg hover:bg-red-100 dark:hover:bg-gray-700 group ${activeContainer === 'addProduct' ? 'bg-red-100 text-[#AB3430] border-l-4 border-[#AB3430]' : ''}`}
                >
                  <span className={`w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-[#AB3430] dark:group-hover:text-white`}>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 24 24" 
                      fill="currentColor" 
                      className={`w-6 h-6 ${activeContainer === 'addProduct' ? 'text-[#AB3430]' : ''}`}
                    >
                      <path 
                        fillRule="evenodd" 
                        d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 22.5a12.696 12.696 0 0 1-6.337-2.434.75.75 0 0 1-.372-.568 6.738 6.738 0 0 1 1.02-4.381ZM15.75 13.5a5.238 5.238 0 0 0-2.559.672 9.007 9.007 0 0 1 2.53 2.427 9.005 9.005 0 0 1 2.53-2.427A5.238 5.238 0 0 0 15.75 13.5Zm-9.75.57a5.238 5.238 0 0 0-2.559.672 9.008 9.008 0 0 1 2.53 2.427 9.007 9.007 0 0 1 2.53-2.427A5.238 5.238 0 0 0 6 14.07Z" 
                        clipRule="evenodd" 
                      />
                    </svg>
                  </span>
                  <div className={`ms-3 ${activeContainer === 'addProduct' ? 'text-[#AB3430]' : ''}`}>Add Product</div>
                </button>
              </li>
              <li>
                <button 
                  onClick={() => handleContainerChange('addcategory')} 
                  className={`w-[100%] flex items-center p-2 text-gray-900 rounded-r-lg hover:bg-red-100 dark:hover:bg-gray-700 group ${activeContainer === 'addcategory' ? 'bg-red-100 text-[#AB3430] border-l-4 border-[#AB3430]' : ''}`}
                >
                  <span className={`w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-[#AB3430] dark:group-hover:text-white`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"  className={`w-6 h-6 ${activeContainer === 'addcategory' ? 'text-[#AB3430]' : ''}`}>
  <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
  <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
</svg>

                
                  </span>
                  <div className={`ms-3 ${activeContainer === 'addcategory' ? 'text-[#AB3430]' : ''}`}>Add Category</div>
                </button>
              </li>
              <li>
                <button 
                  onClick={() => handleContainerChange('addsubcategory')} 
                  className={`w-[100%] flex items-center p-2 text-gray-900 rounded-r-lg hover:bg-red-100 dark:hover:bg-gray-700 group ${activeContainer === 'addsubcategory' ? 'bg-red-100 text-[#AB3430] border-l-4 border-[#AB3430]' : ''}`}
                >
                  <span className={`w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-[#AB3430] dark:group-hover:text-white`}>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"  className={`w-6 h-6 ${activeContainer === 'addsubcategory' ? 'text-[#AB3430]' : ''}`}>
  <path d="M7.5 3.375c0-1.036.84-1.875 1.875-1.875h.375a3.75 3.75 0 0 1 3.75 3.75v1.875C13.5 8.161 14.34 9 15.375 9h1.875A3.75 3.75 0 0 1 21 12.75v3.375C21 17.16 20.16 18 19.125 18h-9.75A1.875 1.875 0 0 1 7.5 16.125V3.375Z" />
  <path d="M15 5.25a5.23 5.23 0 0 0-1.279-3.434 9.768 9.768 0 0 1 6.963 6.963A5.23 5.23 0 0 0 17.25 7.5h-1.875A.375.375 0 0 1 15 7.125V5.25ZM4.875 6H6v10.125A3.375 3.375 0 0 0 9.375 19.5H16.5v1.125c0 1.035-.84 1.875-1.875 1.875h-9.75A1.875 1.875 0 0 1 3 20.625V7.875C3 6.839 3.84 6 4.875 6Z" />
</svg>

                
                  </span>
                  <div className={`ms-3 ${activeContainer === 'addsubcategory' ? 'text-[#AB3430]' : ''}`}>Add Sub Category</div>
                </button>
              </li>
              <li>
                <button 
                  onClick={() => handleContainerChange('addgallery')} 
                  className={`w-[100%] flex items-center p-2 text-gray-900 rounded-r-lg hover:bg-red-100 dark:hover:bg-gray-700 group ${activeContainer === 'addgallery' ? 'bg-red-100 text-[#AB3430] border-l-4 border-[#AB3430]' : ''}`}
                >
                                    <span className={`w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-[#AB3430] dark:group-hover:text-white`}>

                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className={`w-6 h-6 ${activeContainer === 'addgallery' ? 'text-[#AB3430]' : ''}`}>
  <path fill-rule="evenodd" d="M1.5 6a2.25 2.25 0 0 1 2.25-2.25h16.5A2.25 2.25 0 0 1 22.5 6v12a2.25 2.25 0 0 1-2.25 2.25H3.75A2.25 2.25 0 0 1 1.5 18V6ZM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0 0 21 18v-1.94l-2.69-2.689a1.5 1.5 0 0 0-2.12 0l-.88.879.97.97a.75.75 0 1 1-1.06 1.06l-5.16-5.159a1.5 1.5 0 0 0-2.12 0L3 16.061Zm10.125-7.81a1.125 1.125 0 1 1 2.25 0 1.125 1.125 0 0 1-2.25 0Z" clip-rule="evenodd" />
</svg>

                 
                  </span>
                  <div className={`ms-3 ${activeContainer === 'addgallery' ? 'text-[#AB3430]' : ''}`}>Add Gallery</div>
                </button>
              </li>
              <li>
                <button 
                  
                  className={`w-[100%] flex items-center p-2 text-gray-900 rounded-r-lg hover:bg-red-100 dark:hover:bg-gray-700 group `}
                >
                  <span className={`w-5 h-5 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-[#AB3430] dark:group-hover:text-white`}>
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      viewBox="0 0 24 24" 
                      fill="currentColor" 
                      className={`w-6 h-6 `}
                    >
                      <path 
                        fillRule="evenodd" 
                        d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 22.5a12.696 12.696 0 0 1-6.337-2.434.75.75 0 0 1-.372-.568 6.738 6.738 0 0 1 1.02-4.381ZM15.75 13.5a5.238 5.238 0 0 0-2.559.672 9.007 9.007 0 0 1 2.53 2.427 9.005 9.005 0 0 1 2.53-2.427A5.238 5.238 0 0 0 15.75 13.5Zm-9.75.57a5.238 5.238 0 0 0-2.559.672 9.008 9.008 0 0 1 2.53 2.427 9.007 9.007 0 0 1 2.53-2.427A5.238 5.238 0 0 0 6 14.07Z" 
                        clipRule="evenodd" 
                      />
                    </svg>
                  </span>
                  <div className={`ms-3 `}>logout</div>
                </button>
              </li>
              
            </ul>
          </div>
        </aside>
        <div className="sm:ml-64">
          
          {activeContainer === 'dashboard' && <Dashboard />}
          {activeContainer === 'addProduct' && <AddProduct />}
          {activeContainer === 'addcategory' && <AddCategory/>}
          {activeContainer === 'addsubcategory' && <AddSubCategory/>}
          {activeContainer === 'addgallery' && <Gallerys/>}
        

         
        </div>
      </div>
    </>
  );
};

export default AdminDashboard;
