import React, { useState } from 'react';
import { toast } from 'react-hot-toast'; // For toast notifications
import { registerApi } from '../../Apis/Api'; // API call
import logo from "../../assets/logos/logo.png";

const RegisterModal = ({ isVisible, onClose }) => {
    const [showPassword, setShowPassword] = useState(false); // State to manage password visibility
    const [error, setError] = useState(null);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: '',
        street: '',
        city: '',
        country: ''
    });

    const [errors, setErrors] = useState({});

    if (!isVisible) return null;

    const handleOutsideClick = (e) => {
        if (e.target.id === 'modal-container') {
            onClose();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
        validateField(name, value);
    };

    const validateField = (fieldName, value) => {
        const newErrors = { ...errors };

        if (fieldName === 'name' && !value.trim()) {
            newErrors.name = 'Name is required.';
        } else if (fieldName === 'email') {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(value)) {
                newErrors.email = 'Invalid email format.';
            } else {
                delete newErrors.email;
            }
        } else if (fieldName === 'phone') {
            const phoneRegex = /^\d{10}$/;
            if (!phoneRegex.test(value)) {
                newErrors.phone = 'Phone number must be 10 digits.';
            } else {
                delete newErrors.phone;
            }
        } else if (fieldName === 'password' && !value.trim()) {
            newErrors.password = 'Password is required.';
        } else if (fieldName === 'street' && !value.trim()) {
            newErrors.street = 'Street address is required.';
        } else if (fieldName === 'city' && !value.trim()) {
            newErrors.city = 'City is required.';
        } else if (fieldName === 'country' && !value.trim()) {
            newErrors.country = 'Country is required.';
        } else {
            delete newErrors[fieldName];
        }

        setErrors(newErrors);
    };

    const validateForm = () => {
        const newErrors = {};

        if (!formData.name.trim()) newErrors.name = 'Name is required.';
        if (!formData.email.trim()) newErrors.email = 'Email is required.';
        if (!formData.phone.trim()) newErrors.phone = 'Phone number is required.';
        if (!formData.password.trim()) newErrors.password = 'Password is required.';
        if (!formData.street.trim()) newErrors.street = 'Street address is required.';
        if (!formData.city.trim()) newErrors.city = 'City is required.';
        if (!formData.country.trim()) newErrors.country = 'Country is required.';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(null); 
        if (!validateForm()) {
            toast.error('Please correct the errors before submitting.');
            return;
        }

        try {
            const response = await registerApi(formData);
            toast.success('Registration successful!');
            onClose(); // Close modal on success
        } catch (error) {
            toast.error(`Registration failed: ${error.response ? error.response.data : error.message}`);
        }
    };

    return (
        <>
            <div 
                id="modal-container" 
                className="fixed inset-0 bg-black bg-opacity-85 flex justify-center items-center z-50" 
                onClick={handleOutsideClick}
            >
                <div className="relative p-1 rounded-md bg-gradient-to-r from-pink-500 via-red-500 to-red-500 w-full max-w-2xl">
                    <div className="relative bg-[#ffffff] rounded-lg shadow-lg p-5">
                        <button 
                            onClick={onClose} 
                            className="absolute top-4 right-4 text-gray-500 hover:text-gray-800 focus:outline-none"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <img src={logo} alt="Logo" className="w-32 mx-auto mb-6" />
                        <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center valky">Create Your Account</h2>
                        {error && <div className="bg-red-100 text-red-500 p-2 mb-4 text-sm rounded">{error}</div>}

                        <form onSubmit={handleSubmit}>
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                {/* Name Input */}
                                <div>
                                    <input 
                                        type="text" 
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Enter your full name" 
                                        className={`w-full p-3 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-red-400 focus:outline-none`}
                                    />
                                    {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
                                </div>

                                {/* Email Input */}
                                <div>
                                    <input 
                                        type="email" 
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="Enter your email" 
                                        className={`w-full p-3 border ${errors.email ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-red-400 focus:outline-none`}
                                    />
                                    {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
                                </div>

                                {/* Phone Input */}
                                <div>
                                    <input 
                                        type="tel" 
                                        name="phone"
                                        value={formData.phone}
                                        onChange={handleChange}
                                        placeholder="Enter your phone number" 
                                        className={`w-full p-3 border ${errors.phone ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-red-400 focus:outline-none`}
                                    />
                                    {errors.phone && <p className="text-red-500 text-sm mt-1">{errors.phone}</p>}
                                </div>

                                {/* Password Input */}
                                <div className="relative">
    <input 
        type={showPassword ? "text" : "password"} // Change type based on visibility
        name="password"
        value={formData.password}
        onChange={handleChange}
        placeholder="Create a password" 
        className={`w-full p-3 border ${errors.password ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-red-400 focus:outline-none`}
    />
    {errors.password && <p className="text-red-500 text-sm mt-1">{errors.password}</p>}

    {/* Toggle visibility icon */}
    <button 
        type="button" 
        onClick={() => setShowPassword(!showPassword)} 
        className="absolute right-3 top-3 text-gray-500 hover:text-gray-800 focus:outline-none"
    >
        {showPassword ? (
           <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6">
                <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                <path fillRule="evenodd" d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 0 1 0-1.113ZM17.25 12a5.25 5.25 0 1 1-10.5 0 5.25 5.25 0 0 1 10.5 0Z" clipRule="evenodd" />
            </svg>
        ) : (
           
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="h-6 w-6">
              <path d="M3.53 2.47a.75.75 0 0 0-1.06 1.06l18 18a.75.75 0 1 0 1.06-1.06l-18-18ZM22.676 12.553a11.249 11.249 0 0 1-2.631 4.31l-3.099-3.099a5.25 5.25 0 0 0-6.71-6.71L7.759 4.577a11.217 11.217 0 0 1 4.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113Z" />
              <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0 1 15.75 12ZM12.53 15.713l-4.243-4.244a3.75 3.75 0 0 0 4.244 4.243Z" />
              <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 0 0-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 0 1 6.75 12Z" />
          </svg>
        )}
    </button>
</div>
                            </div>

                            {/* Address Inputs */}
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-4">
                                <div>
                                    <input 
                                        type="text" 
                                        name="street"
                                        value={formData.street}
                                        onChange={handleChange}
                                        placeholder="Enter your street address" 
                                        className={`w-full p-3 border ${errors.street ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-red-400 focus:outline-none`}
                                    />
                                    {errors.street && <p className="text-red-500 text-sm mt-1">{errors.street}</p>}
                                </div>

                                <div>
                                    <input 
                                        type="text" 
                                        name="city"
                                        value={formData.city}
                                        onChange={handleChange}
                                        placeholder="Enter your city" 
                                        className={`w-full p-3 border ${errors.city ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-red-400 focus:outline-none`}
                                    />
                                    {errors.city && <p className="text-red-500 text-sm mt-1">{errors.city}</p>}
                                </div>

                                {/* Country Input */}
                                <div className="md:col-span-2">
                                    <select 
                                        name="country"
                                        value={formData.country}
                                        onChange={handleChange}
                                        className={`w-full p-3 border ${errors.country ? 'border-red-500' : 'border-gray-300'} rounded-lg focus:ring-2 focus:ring-red-400 focus:outline-none`}
                                    >
                                        <option value="">Select your country</option>
                                        <option value="Nepal">Nepal</option>
                                        <option value="Canada">Canada</option>
                                        <option value="UK">UK</option>
                                        <option value="Australia">Australia</option>
                                    </select>
                                    {errors.country && <p className="text-red-500 text-sm mt-1">{errors.country}</p>}
                                </div>
                            </div>

                            <div className="flex justify-center mt-8">
                                <button 
                                    type="submit"
                                    className="py-3 px-10 bg-[#AB3430] lg:w-fit w-full text-white font-semibold rounded-lg hover:bg-[#812724] transition duration-300"
                                >
                                    Register
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RegisterModal;
