import React from "react";
import { TECarousel, TECarouselItem } from "tw-elements-react";
import women from "../../assets/images/women4.jpg";
import women2 from "../../assets/images/women2.jpg";
import women3 from "../../assets/images/women3.jpg";

export default function Sliders() {
  return (
    <TECarousel showControls showIndicators ride="carousel">
      <div className="relative w-full overflow-hidden after:clear-both after:block after:content-['']">
        <TECarouselItem
          itemID={1}
          className="relative float-left -mr-[100%] hidden w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
        >
          <img
            src={women}
            alt="women in kurta"
            className="w-full h-[600px] object-cover"
          />
        </TECarouselItem>

        <TECarouselItem
          itemID={2}
          className="relative float-left hidden -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
        >
          <img
            src={women2}
            alt="women in kurta"
            className="w-full h-[600px] object-cover"
          />
        </TECarouselItem>

        <TECarouselItem
          itemID={3}
          className="relative float-left hidden -mr-[100%] w-full transition-transform duration-[600ms] ease-in-out motion-reduce:transition-none"
        >
          <img
            src={women3}
            alt="women in kurta"
            className="w-full h-[600px] object-cover"
          />
        </TECarouselItem>
      </div>
    </TECarousel>
  );
}
