import React from 'react'
import image from "../../assets/images/img1.jpg"
import fone from "../../assets/images/fone.png"

const AddCard = () => {
  return (
   <>
   <h1 className='text-center p-5 bg-[#E3DCD7] valky font-bold text-gray-600'>
   Shopping Cart
   </h1>
   <div className='grid lg:grid-cols-3 px-10 pt-10 pb-10'>
    <div className='col-span-2'>
    <div className="relative overflow-x-auto  mt-3">
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 responsive">
        <thead className="text-xs text-gray-700 uppercase  dark:bg-gray-700 dark:text-gray-400 border-b">
          <tr>
          
            <th scope="col" className="px-6 py-3">Product Name</th>
            <th scope="col" className="px-6 py-3">Price</th>
            <th scope="col" className="px-6 py-3">Quantity</th>
            <th scope="col" className="px-6 py-3">Subtotal</th>
            
          </tr>
        </thead>
        <tbody>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
          <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex items-center gap-2">
              <img src={image} alt="product" className="h-[100px] w-[70px]" />
              <div>
                <h5>
                Kurtha1
                    </h5>
                    <h5>xl</h5>
                
              </div>
            </th>
            <td>Kurtha1</td>
            <td className="px-6 py-4">admin</td>
          
            <td className="px-6 py-4">Rs 1000</td>
       
          </tr>
          <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white flex items-center gap-2">
              <img src={image} alt="product" className="h-[100px] w-[70px]" />
              <div>
                <h5>
                Kurtha1
                    </h5>
                    <h5>xl</h5>
                
              </div>
            </th>
          
            <td className="px-6 py-4">Rs 1000</td>
            <td className="px-6 py-4">admin</td>
            <td className="px-6 py-4">
             Rs 1000
            </td>
          </tr>
          
          
          {/* Add more rows if necessary */}
        </tbody>
      </table>
    </div>
        </div>
        <div>
      <div className='bg-[#F4F5F9] p-5 mx-5'>
      <h3 className='font-bold'>Order Summary</h3>
      <div className='my-5'>
      <hr/>
      </div>


      <div className='flex justify-between'>
        <div>
        Subtotal 
            </div>
            <div className='font-medium'>Rs 1000</div>
            </div>
            <div className='flex justify-between mt-5'>
        <div>
        Shipping 
            </div>
            <div className='font-medium'>Rs 1000</div>
            </div>
            <div className='my-5'>
      <hr/>
      </div>
      <div className='flex justify-between'>
        <h2>Total</h2>
            <h2 className='font-medium'>Rs 2000</h2>
            </div>
            <button className='bg-[#AB3430] text-white px-5 py-3 w-full mt-5'>
            PROCEED TO CHECKOUT
            </button>
            <div className='my-5'>
      <hr/>
      </div>
      <div>Accept Payment Method</div>
      <img src={fone} alt="fone pay" className='mt-5'/>
        </div>
        </div>
        
    
   </div>
   </>
  )
}

export default AddCard