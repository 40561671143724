import React from 'react'
import whatsapp from "../../assets/icons/whatsapp.png"

import Card from '../component/Card'
import bg from "../../assets/images/bg.png"
import { Link } from 'react-router-dom'
import Sliders from '../component/Sliders'
// import Footer from '../component/Footer'

const Home = () => {
    return (
        <div >
            <div
                className="fixed top-0 left-0 w-full h-full bg-cover bg-center"
                style={{ backgroundImage: `url(${bg})` }}
            ></div>
            <div className=' relative z-40'>
<Sliders/>
              
                <div className='shipping  mx-auto pb-10 bg-white'>
                    <div className='grid grid-cols-3  pt-10 mb-10'>
                        <div className='flex gap-5 items-center lg:justify-center mt-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#AB3430" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 10.5V6a3.75 3.75 0 1 0-7.5 0v4.5m11.356-1.993 1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 0 1-1.12-1.243l1.264-12A1.125 1.125 0 0 1 5.513 7.5h12.974c.576 0 1.059.435 1.119 1.007ZM8.625 10.5a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm7.5 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                            </svg>
                            <div className=''>
                                <div className='text-[#2b2b2b] '>Free Shipping</div>
                                <div className='text-[#636363] hidden lg:block md:block'>On Orders Over $50</div>
                            </div>


                        </div>
                        <div className='flex gap-5 items-center lg:justify-center mt-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#AB3430" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                            </svg>

                            <div className=''>

                                <div className='text-[#2b2b2b] '>Buy Now. Pay on Delivery</div>
                                <div className='text-[#636363] hidden lg:block md:block'>can pay on delivery</div>
                            </div>


                        </div>
                        <div className='flex gap-5 items-center lg:justify-center mt-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#AB3430" class="size-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>

                            <div className='k'>

                                <div className='text-[#2b2b2b] '>Secured Payment</div>
                                <div className='text-[#636363] hidden lg:block md:block'>Guarantee 100% secure payments<br /> on online on our website</div>
                            </div>


                        </div>

                    </div>
                    <hr />
                </div>
                <div className='BestSeller px-10 bg-white pb-10'>
                    <div className='container mx-auto'>

                
                    <div className='text-[#767676] text-3xl font-medium pb-5'>Best Seller</div>
                    <div className='grid grid-cols-2 lg:grid-cols-4 gap-10  pb-10'>
                        <Card />
                        <Card />
                        <Card />
                        <Card />

                    </div>
                    </div>

                </div>
                <div className='contact bg-transparent'>
                    <div className='h-[60vh] flex justify-center items-center text-white'>
                        <div className='text-center'>
                            <div className='text-6xl'>Pranu Collection</div>
                            <p className='mt-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, <br />sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                            <button className='bg-[#AB3430] text-white px-5 py-2 rounded-full mt-5'>Contact Us</button>
                        </div>

                    </div>
                </div>
                <div className='BestSeller  px-10 bg-white pb-10 pt-10'>
                    <div className='container mx-auto'>
                    <div className='text-[#767676] text-3xl font-medium pb-5'>New Arrivals</div>
                    <div className='grid grid-cols-2 lg:grid-cols-4 gap-10  pb-10'>
                        <Link to="/viewProduct">
                        <Card />
                        </Link>
                     
                        <Card />
                        <Card />
                        <Card />

                    </div>
</div>
<div className='fixed bottom-0 right-0 p-5 hover:cursor-pointer hover:grayscale-90'>
<a aria-label="Chat on WhatsApp" href="https://wa.me/9848556062"> 
        <img src={whatsapp} alt="whatsapp"  className='h-[60px]'/>
        </a>
        </div>
                </div>


          
      
               
            </div>

        </div>
    )
}

export default Home