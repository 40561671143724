import React, { useState } from 'react';
import img1 from '../../assets/images/women2.jpg';
import img2 from '../../assets/images/women3.jpg'
import img4 from '../../assets/images/women3.jpg'
const ImageGallery = () => {
  // State to manage the current large image
  const [largeImage, setLargeImage] = useState(img1);

  // Handler to change the large image
  const handleImageClick = (image) => {
    setLargeImage(image);
  };

  return (
    <div className='p-5 flex justify-end gap-2'>
      <div className='grid grid-rows-4 gap-2 w-[100px]'>
        <img
          src={img1}
          alt="Thumbnail 1"
          className='h-[150px] cursor-pointer hover:border-2 hover:border-red-400'
          onClick={() => handleImageClick(img1)}
        />
        <img
          src={img2}
          alt="Thumbnail 2"
          className='h-[150px] cursor-pointer hover:border-2 hover:border-red-400'
          onClick={() => handleImageClick(img2)}
        />
          <img
          src={img1}
          alt="Thumbnail 1"
          className='h-[150px] cursor-pointer hover:border-2 hover:border-red-400'
          onClick={() => handleImageClick(img1)}
        />
        <img
          src={img4}
          alt="Thumbnail 2"
          className='h-[150px] cursor-pointer hover:border-2 hover:border-red-400'
          onClick={() => handleImageClick(img4)}
        />
      </div>
      <div className='h-[650px] w-[400px]'>
        <img src={largeImage} alt="Large display" className='w-full h-full object-cover'/>
      </div>
    </div>
  );
};

export default ImageGallery;
