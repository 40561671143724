import React from 'react'
import bg from "../../assets/images/bg.png"
import women from "../../assets/images/women2.jpg"
import insta from "../../assets/icons/in.png"

const AboutUs = () => {
    return (
        <>
            <div className=''>
                <div
                    className="fixed top-0 left-0 w-full h-full bg-cover bg-center"
                    style={{ backgroundImage: `url(${bg})` }}
                ></div>
                <div className='mx-auto relative '>
                    <div className='bg-[#E3DCD7]'>


                    <div className='container mx-auto'>
                    <div className='grid lg:grid-cols-3 grid-cols-1 pt-10 px-10   pb-10 ' >
                        <div className='col-span-2'>
                            <div className='flex flex-col justify-between'>
                                <h2 className='text-gray-400'>
                                    FOUNDED
                                    2018
                                </h2>
                                <div className='text-[40px] pt-10 text-gray-600 valky font-bold'>Stand out of the crowd with <br /> own style</div>
                            </div>


                        </div>
                        <div className=''>
                            <div className='flex gap-10'>
                                <div>
                                    <h1 className='font-bold text-[#AB3430] pt-5'>95%</h1>
                                    <h1 className='font-bold text-[#AB3430] pt-5'>3+</h1>
                                    <h1 className='font-bold text-[#AB3430] pt-5'>3 Lakh</h1>


                                </div>
                                <div>
                                    <h5 className="pt-5 text-gray-600">Satisfitation<br />client
                                    </h5>
                                    <h5 className="pt-5 text-gray-600">Store on <br />Country
                                    </h5>
                                    <h5 className='pt-5 text-gray-600'>Order <br />client
                                    </h5>
                                </div>
                            </div>


                        </div>
                    </div>
                    </div>
                    </div>
                    <div className='bg-transparent  h-[350px] container mx-auto'>
                        <div className='mx-10'>
                        <div className='pt-5 text-[60px] text-white valky '>
                            Pranu Collection
                        </div>
                        <p className='text-white' i>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>

                        </div>
                        
                    </div>
                    <div className='bg-white px-auto px-10 pt-10 pb-10'>
                        <div className='grid lg:grid-cols-2'>
                            <div>  <div className='relative flex flex-col items-center'>
                                <div className='border border-gray-500 rounded-[300px] lg:w-[400px] w-[200px] md:w-[300px] overflow-hidden lg:p-[30px] p-[20px] flex items-center justify-center'>
                                    <img
                                        src={women}
                                        alt='women in kurta'
                                        className=' lg:h-[600px] h-[400px] object-cover rounded-[280px]'
                                    />

                                </div>
                                <div className='absolute top-[0%] left-[50%] hidden lg:block md:block '>

                                </div>


                            </div></div>
                            <div className=' flex flex-col justify-center'>
                                <h1>Pranu Collection</h1>
                                <p className="pt-10">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                                <p>Lo

                                    rem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, </p>

                            </div>


                        </div>

                    </div>
                    <div className='flex justify-center bg-[#F2F2F2]'>
                        <div className='pt-10 pb-10'>
                            <div>
                                <div className='flex gap-5 items-center valky font-bold text-[24px] pb-10 text-gray-600 justify-center'>
                                    <img src={insta} alt="insta" className='h-[30px] grayscale' />
                                    official_pranu_collection
                                </div>
                            </div>
                            <div className='flex justify-between gap-2 px-10'>
                                <img src={women} className="insta h-[200px] w-[200px] object-cover" />
                                <img src={women} className="insta h-[200px] w-[200px] object-cover" />
                                <img src={women} className="insta h-[200px] w-[200px] object-cover" />
                                '<img src={women} className="insta h-[200px] w-[200px] object-cover" />

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs